import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import "./summary.scss"
import { IMAGE_URL_CONFIG, URLS } from "../../../../constants/url";
import Barcode from "react-barcode";
import { useNavigate, useParams } from "react-router";
import { ROUTES } from "../../../../constants/path";
import { ANG_MEMBERSHIP_EXPIRY_DAYS, MEMBERSHIP_TYPE } from "../../../../constants/app";
import { getRenewalInfoApi, walletPassApi } from "../../../../services/auth";
import { formatLoyaltyPayload, formatMembershipExpireDate, formatMembershipExpireDays, formatMembershipType, formatRewardPoints } from "../../../../utils/rewards";
import { setContentModal, toggleContentModal } from "../../../../store/application/action";
import { renewMembership, upgrademembership } from "../../../../services/rewards";
import { getRenewalInfo } from "../../../../store/auth/action";


interface ISummaryProps { }

const Summary: FC<ISummaryProps> = () => {

  const { countryId, userDetails, memberDetail, isLoggedIn, currentCinema, renewalInfo } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      userDetails: state.authReducer.userDetails,
      memberDetail: state.authReducer.memberDetail,
      isLoggedIn: state.authReducer.isLoggedIn,
      currentCinema: state.applicationReducer.currentCinema,
      renewalInfo: state.authReducer.renewalInfo
    })
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expireDate, setExpireDate] = useState<string>('');
  const [isGoingExpire, setIsGoingExpire] = useState<boolean>(false);
  const [renewalType, setRenewalType] = useState('manual');
  const { renewal } = useParams();

  useEffect(()=>{
    if(renewal && renewal == 'renewal' && isLoggedIn && currentCinema && isGoingExpire){
      onRenewal();
    }else if(renewal && renewal == 'editRenewal' && isLoggedIn && currentCinema){
      editRenewal();
    }
  },[renewal, isGoingExpire, isLoggedIn, currentCinema])

  const txnPoints = useMemo(() => {
    return formatRewardPoints(memberDetail!);
  }, [memberDetail]);


  useEffect(() => {
    if(userDetails && userDetails.id){
      const payload = {
        memberRefId: userDetails?.id,
        countryId,
        emailId: userDetails?.email,
      }
        dispatch(getRenewalInfo(payload));
    }
  }, [userDetails, memberDetail])

  const availableRewards = () => {
    navigate(`/${ROUTES.REWARDS}/${ROUTES.REWARDITEM}`);
  };

  const googleWalletClick = async () => {
    if (userDetails && userDetails?.cardNumber && userDetails.token) {
      window.open(URLS.GOOGLE_WALLET + userDetails.token, "_blank");
    } else {
      const {
        status,
        data: { data, statusCode },
      } = await walletPassApi(formatLoyaltyPayload(userDetails!, countryId, memberDetail!, 'google'));
      if (status === 200 && statusCode === 200 && data?.token) {
        window.open(URLS.GOOGLE_WALLET + data?.token, "_blank");
      }
    }
  };

  const appleWalletClick = async () => {
    const {
      status,
      data: { data, statusCode },
    } = await walletPassApi(formatLoyaltyPayload(userDetails!, countryId, memberDetail!, 'apple'));
    if (status === 200 && statusCode === 200 && data?.token) {
      window.open(URLS.APPLE_WALLET + data?.token, "_blank");
    }
  };

  const membershipType = useMemo(() => {
    return formatMembershipType(memberDetail!)
  }, [memberDetail])

  useEffect(() => {
    setExpireDate(formatMembershipExpireDate(memberDetail!));
    setIsGoingExpire(formatMembershipExpireDays(memberDetail!) <= ANG_MEMBERSHIP_EXPIRY_DAYS);
  }, [memberDetail])

  const cancelSubscrption = () => {
    dispatch(setContentModal({ type: ROUTES.CANCEL_MEMBERSHIP }));
    dispatch(toggleContentModal(true));
  }

  const editRenewal = () => {
    dispatch(setContentModal({ type: ROUTES.EDIT_RENEWAL }));
    dispatch(toggleContentModal(true));
  }


  const onRenewal = async () => {
    await renewMembership(countryId, currentCinema, userDetails, dispatch);
  };

  const upgrade = async () => {
    await upgrademembership(countryId, currentCinema, userDetails, dispatch);
  }


  return (
    <div className="summary_details">
      <div className="col-md-12 summary_heading">
        <div className="user-details">
          <div className="user-name">
            <span>
              Hi{" "}
              {userDetails?.firstName ? userDetails.firstName : ""}
            </span>{" "}
            <span>
              {userDetails?.lastName ? userDetails.lastName : ""},
            </span>
          </div>
          <div className="description">
            Welcome to your Angelika Rewards home.
          </div>
        </div>
        <div className={`reward_details ${membershipType === MEMBERSHIP_TYPE.GOLD ?
          "gold" : "blue"}`}>
          <div className="logo">
            <img
              className="w-100"
              src={
                `${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}` +
                `${membershipType === MEMBERSHIP_TYPE.GOLD ? "angelika_rewards_gold.svg" : "angelika_rewards_club.svg"}`
              }
            /></div>
          <div className="member_detail">
            <div className="member_label">Member #</div>
            <div className="member-no">
              {memberDetail?.LoyaltyMember?.CardNumber}
            </div>
            <div className="club_bar_code" onClick={() => { }}>
              <Barcode
                width={3}
                height={65}
                displayValue={false}
                value={memberDetail?.LoyaltyMember?.CardNumber!}
              />
            </div>
            <div className="label-select-barcode">Select to scan barcode</div>
            {membershipType === MEMBERSHIP_TYPE.GOLD && memberDetail?.LoyaltyMember?.ExpiryDate ? <div className={`expires-on ${isGoingExpire ? 'active' : ''}`}>
              <div className="expires-label">Expires On</div>
              <div className="expires-value">{expireDate}</div>
            </div> : null}
          </div>
        </div>
        {membershipType === MEMBERSHIP_TYPE.GOLD ? <div className="membership_info">
          <div className="info">
            <div className="label">Subscription Period</div>
            <div className="desc">1 year</div>
          </div>
          <div className="info">
            <div className="label">Renewal Process</div>
            <div className="desc">{renewalInfo?.subscriptionRenewalType}</div>
          </div>
          <div className="info">
            <button className="bttn edit-btn" onClick={editRenewal}>
              <img className="mx-1" src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'pencil.svg'}`} />
              <span className="mx-1">Edit</span>
            </button>
          </div>
          {isGoingExpire ? <div className="info">
            <button className="btn btn-gold" onClick={onRenewal}>Renew Membership</button>
          </div> : null}
        </div> : null}
        <div className="member_wallet">
          <div onClick={googleWalletClick} className="g_pay">
            <img
              src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'google_wallet.svg'}
              className="mCS_img_loaded"
            />
          </div>
          <div onClick={appleWalletClick} className="g_pay">
            <img
              src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'apple_wallet.svg'}
              className="mCS_img_loaded"
            />
          </div>
        </div>
        {membershipType === MEMBERSHIP_TYPE.CLUB ? <>
          <div className="upgrade_to_angelika">Upgrade to Angelika Rewards Gold</div>
          <button className="btn btn-gold" onClick={upgrade}>UPGRADE NOW</button>
        </> : null}
      </div>
      <div className="point_section">
        <div className="desc">My current points</div>
        <div className="points">{txnPoints.toLocaleString()}</div>
        {txnPoints > 0 ? <button className={`btn ${membershipType === MEMBERSHIP_TYPE.GOLD ? 'btn-gold' : 'btn-club'}`} onClick={availableRewards}>SEE MY AVAILABLE REWARDS</button> : null}
        {membershipType === MEMBERSHIP_TYPE.GOLD ? <div className="cancel_subscription" onClick={cancelSubscrption}>
          CANCEL ANGELIKA GOLD MEMBERSHIP
        </div> : null}
      </div>
    </div>
  );
};

export default Summary;
