import { AnyAction } from "redux";
import { ACTIONS, IAuth } from "./type";
import { formaSignupUser, formatUser } from "../../utils/formatter";
import Storage from "../../utils/storage";

const initialState: IAuth = {
  userDetails: null,
  memberDetail: null,
  savedCards: [],
  savedGiftCards: [],
  isLoggedIn: false,
  authError: "",
  openModal: false,
  openReelClubModal: false,
  openPhysicalCardModal: false,
  openUpdateProfileModal: false,
  openManageCardModal: false,
  openManageGiftCardModal: false,
  membershipPurchase: null,
  renewalInfo: null
};

export default function authReducer(
  state = initialState,
  action: AnyAction
): IAuth {
  switch (action.type) {

    case ACTIONS.SIGN_UP_AS_GUEST:
      return {
        ...state,
      };
    case ACTIONS.SIGN_UP_SUCCESS:
      return {
        ...state,
        authError: null,
        userDetails: state.userDetails
          ? { ...state.userDetails, ...formaSignupUser(action.payload.data) }
          : formaSignupUser(action.payload.data),
      };
    case ACTIONS.SIGN_UP_ERROR:
      return {
        ...state,
        authError: "",
        isLoggedIn: false,
      };

    case ACTIONS.SIGN_IN_WATCHER:
      return {
        ...state,
        isLoggedIn: false,
        authError: null
      };

    case ACTIONS.SIGN_IN_SUCCESS:

      const user = formatUser(action.payload);
      Storage.setItem('LOCAL', 'userDetails', user);
      return {
        ...state,
        authError: null,
        userDetails: user,
        isLoggedIn: user.result?.memberRefId !== "" ? true : false,
      };
    case ACTIONS.SIGN_IN_FAILURE:
      return {
        ...state,
        authError: action.payload,
        isLoggedIn: false,
      };
    case ACTIONS.SET_USER_DETAILS:
      console.log('action.payload', action.payload)
      Storage.setItem('LOCAL', 'userDetails', action.payload);
      return {
        ...state,
        userDetails: action.payload,
        isLoggedIn: action.payload?.result?.memberRefId !== "" ? true : false,
      }
    case ACTIONS.MEMBER_VALIDATION_WATCHER:
      return {
        ...state,
        authError: null,
      };
    case ACTIONS.MEMBER_VALIDATION_SUCCESS:
      Storage.setItem('LOCAL', 'memberDetail', action.payload);
      return {
        ...state,
        authError: null,
        memberDetail: action.payload,
      };
    case ACTIONS.MEMBER_VALIDATION_FAILURE:
      return {
        ...state,
        authError: action.payload,
      };

    case ACTIONS.SHOW_LOGIN:
      return {
        ...state,
        openModal: action.payload
      }

    case ACTIONS.FORGOT_PASSWORD_WATCHER:
      return {
        ...state,
        isLoggedIn: false,
        authError: null
      };
    case ACTIONS.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        authError: null,
        isLoggedIn: false,
      };
    case ACTIONS.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        authError: action.payload,
        isLoggedIn: false,
      };
    case ACTIONS.GET_SAVED_CARDS_WATCHER:
      return {
        ...state,
        authError: "",
      };
    case ACTIONS.GET_SAVED_CARDS_SUCCESS:
      return {
        ...state,
        savedCards: action.payload,
      };
    case ACTIONS.GET_SAVED_CARDS_ERROR:
      return {
        ...state,
        savedCards: [],
        authError: "",
      };
    case ACTIONS.SHOW_REEL_CLUB_MODAL:
      return {
        ...state,
        openReelClubModal: action.payload
      }
    case ACTIONS.SHOW_PHYSICAL_CARD_MODAL:
      return {
        ...state,
        openPhysicalCardModal: action.payload
      }
    case ACTIONS.SHOW_UPDATE_PROFILE_MODAL:
      return {
        ...state,
        openUpdateProfileModal: action.payload
      }
    case ACTIONS.SHOW_MANAGE_CARD_MODAL:
      return {
        ...state,
        openManageCardModal: action.payload
      }
    case ACTIONS.SHOW_MANAGE_GIFT_CARD_MODAL:
      return {
        ...state,
        openManageGiftCardModal: action.payload
      }
    case ACTIONS.SET_LOGOUT:
      Storage.removeItem('LOCAL', 'userDetails');
      Storage.removeItem('LOCAL', 'memberDetail');
      return {
        ...state,
        userDetails: null,
        memberDetail: null,
        isLoggedIn: false,
      }
    case ACTIONS.MEMBERSHIP_PURCHASE:
      return {
        ...state,
        membershipPurchase: action.payload
      }

    case ACTIONS.GET_SAVED_GIFT_CARDS_SUCCESS:
      return {
        ...state,
        savedGiftCards: action.payload,
      };

    case ACTIONS.GET_SAVED_GIFT_CARDS_ERROR:
      return {
        ...state,
        savedGiftCards: [],
      };
    case ACTIONS.RENEWAL_INFO_SUCCESS:
      return {
        ...state,
        renewalInfo: action.payload
      }
    case ACTIONS.RENEWAL_INFO_ERROR:
      return {
        ...state,
        renewalInfo: null
      }

    default:
      return state;
  }
}
