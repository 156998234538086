import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Barcode from "react-barcode";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import { upcomingTicketApi } from "../../../../services/auth";
import moment from "moment";
import { IUpcomingTicket } from "../../../../models/auth";
import { useNavigate } from "react-router";
import Slick from "react-slick";
import "./upcomingShow.scss";

interface IUpcomingShowProps { }

const UpcoingShow: FC<IUpcomingShowProps> = () => {
  const { countryId, userDetails, isLoggedIn, currentCinema, openSlider } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    userDetails: state.authReducer.userDetails,
    isLoggedIn: state.authReducer.isLoggedIn,
    currentCinema: state.applicationReducer.currentCinema,
    openSlider: state.applicationReducer.openSlider,
  }));

  const navigator = useNavigate();
  const dispatch = useDispatch();
  const settings = {
    className: "",
    centerMode: false,
    centerPadding: "10px",
    dots: true,
    lazyload: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    cssEase: "linear",
    fade: false,
    slidesToScroll: 1,
    initialSlide: 1,
    slidesPerRow: 1,
    swipeToSlide: true,
    // adaptiveHeight: true,
    arrows: true,
    appendDots: (dots: any) => <ul>{dots}</ul>,
    customPaging: (i: any) => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    ),
  };


  const [upcomingShows, setUpcomingShow] = useState<IUpcomingTicket[]>(
    []
  );

  const getUpcomingTickets = async () => {
    const payload = {
      countryId: countryId,
      emailId: userDetails?.email!,
      requestType: API_REQUEST_TYPE.UPCOMING_TRANSACTION,
      showAll: true
    };
    const { status, response } = (await upcomingTicketApi(payload)) as any;
    if (status) {
      const {
        data: { data },
      } = response;
      setUpcomingShow(data);
    }
  };

  useEffect(() => {
    if (userDetails && isLoggedIn && openSlider && openSlider.open) {
      getUpcomingTickets();
    }
  }, [userDetails, isLoggedIn, openSlider]);

  return (
    <div className="upcoming-show">
      <Slick
        {...settings}
        className="carousel"

      >
        {upcomingShows?.map((upcomingShow: any, index: number) => (
          <div className="col-md-12">
            <div className="movie-detail-card section_divided">
              <div className="movie-card">
                <div className="media">
                  <div className="media-left">
                    <img
                      src={upcomingShow.movieImage}
                      className="media-object"
                      alt={upcomingShow.movieName}
                    />
                  </div>
                  <div className="media-body">
                    <div className="movie_title">{upcomingShow.movieName}</div>
                    <div className="movie_sub_title">
                      {upcomingShow.cinemaName + " Cinema"}
                    </div>
                    <div className="show_details_wrap">
                      <div className="show_details">
                        <div className="time">{upcomingShow.sesionTime}</div>
                        <div className="day">
                          {moment(upcomingShow.sesionDate).format("dddd D MMMM")}
                        </div>
                      </div>
                    </div>
                    {upcomingShow.seats ? <div className="seat">SEATS</div> : null}
                    <span className="seat_no">{upcomingShow.seats}</span>
                  </div>
                </div>
                <div className={`ticket_info_details`}>
                  <div className="amount_details">
                    <div className="total">TOTAL</div>
                    <div className="total-amount">
                      $
                      {(
                        +upcomingShow.ticketAmount +
                        +upcomingShow.BookingFee +
                        (upcomingShow.concessionAmount
                          ? +upcomingShow.concessionAmount
                          : 0.0)
                      ).toFixed(2)}
                    </div>
                    <div className="oth-details">
                      {upcomingShow.numberOfSeats}X SEATS: $
                      {Number(upcomingShow.ticketAmount).toFixed(2)}
                    </div>
                    {upcomingShow.concessionAmount ? (
                      <div className="oth-details">
                        FOOD PRICE: $
                        {upcomingShow.concessionAmount
                          ? upcomingShow.concessionAmount
                          : "0.00"}
                      </div>
                    ) : null}
                    <div className="oth-details">
                      BOOKING FEE: ${upcomingShow.BookingFee}
                    </div>
                  </div>
                  <div className="ticket_bar_code">
                    <Barcode
                      width={1}
                      height={50}
                      displayValue={true}
                      value={upcomingShow.bookingId ? upcomingShow.bookingId : ""}
                    />
                  </div>
                </div>
                <div className="refund-swap">
                  <a className="refund-swap-btn" target="_blank" href={upcomingShow.swapSeatUrl}>Swap Seats</a>
                  <a className="refund-swap-btn" target="_blank" href={upcomingShow.refundUrl}>Refund</a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slick>
    </div>
  );
};

export default UpcoingShow;
