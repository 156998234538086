import { IAuthMenu, IMOVIE_TYPES, ISide_Menu } from "../models/common";
import { ROUTES } from "./path";
import { URLS } from "./url";

export enum DOMAINS {
    AUS = 'readingcinemas.com.au',
    NZ = 'readingcinemas.co.nz',
    ANG = 'angelikacinemas.com.au',
    AFC = 'angelikafilmcentre.com.au',
    STA = 'statecinema.com.au',
    US = 'readingcinemas.com',
}

export enum COUNTRY {
    AUS = '1',
    NZ = '2',
    ANG = '3',
    STA = '4',
    US = '5',
    CON = '6',
    AFC = '7'
}

export enum BRANDS {
    AU = 'AU',
    US = 'US'
}

export const BRAND_ID = {
    [COUNTRY.AUS]: BRANDS.AU,
    [COUNTRY.NZ]: BRANDS.AU,
    [COUNTRY.ANG]: BRANDS.AU,
    [COUNTRY.STA]: BRANDS.AU,
    [COUNTRY.US]: BRANDS.US,
    [COUNTRY.CON]: BRANDS.US,
    [COUNTRY.AFC]: BRANDS.US,
}

export const CURRENCY = {
    [COUNTRY.AUS]: 'AUD',
    [COUNTRY.NZ]: 'NZD',
    [COUNTRY.ANG]: 'AUD',
    [COUNTRY.STA]: 'AUD',
    [COUNTRY.US]: 'USD',
    [COUNTRY.CON]: 'USD',
    [COUNTRY.AFC]: 'USD',
}

export const COUNTRY_CODE = {
    [COUNTRY.AUS]: 'AU',
    [COUNTRY.NZ]: 'NZ',
    [COUNTRY.ANG]: 'AU',
    [COUNTRY.STA]: 'AU',
    [COUNTRY.US]: 'US',
    [COUNTRY.CON]: 'USD',
    [COUNTRY.AFC]: 'USD',
}
export const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCH_SITE_KEY;

export const TIMER_DURATION = 600;
export const TIMER_DURATION_FB = 300;

export const CLICK_TO_PAY_CONFIG = {
    HOST_URL: "https://paynow.pmnts-sandbox.io",
    USER_NAME: "TESTreadingcinemas",
    CURRENCY: "AUD",
    SHARED_SECRET: "9623d56c95"
}

export enum PAYMENT_PROVIDERS {
    FATZEBRA = 'fatzebra',
    STRIPE = 'stripe',
    PAYEEZY = 'payeezy'
}

export const GIFT_CARD_CINEMA_ID = {
    [COUNTRY.NZ]: 1,
    [COUNTRY.AUS]: '0000000001',
    [COUNTRY.STA]: '0000000001',
    [COUNTRY.ANG]: '0000000001',
    [COUNTRY.US]: '0000000024',
    [COUNTRY.AFC]: '0000000024',
    [COUNTRY.CON]: '0000000024',
}

export const GIFT_CARD_DELIVERY_FEE = {
    NZ: 9.57,
    AU: 9,
    ANG: 9,
    STA: 9
}

export const ANG_MEMBERSHIP_LEVEL_ID = 4;

export const MEMBERSHIP_TYPE = {
    GOLD:'gold',
    CLUB:'club'
}

export const ANG_MEMBERSHIP_EXPIRY_DAYS = 15;

export const SIDE_MENU: ISide_Menu[] = [
    {
        header: {
            [COUNTRY.AUS]: "CINEMAS",
            [COUNTRY.NZ]: "CINEMAS",
            [COUNTRY.ANG]: "",
            [COUNTRY.STA]: "INFO"
        },
        alias: "cinemas",
        menus: [
            {
                label: "Location Map",
                alias: "locationMap",
                type: "page",
                path: `/${ROUTES.CINEMAS}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "Luxury Cinemas",
                alias: "luxuryCinemas",
                type: "page",
                path: `/${ROUTES.LUXURY_CINEMAS}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "VIP Cinema Experience",
                alias: "vipCinemaExperience",
                type: "page",
                path: `/${ROUTES.VIP_EXPERIENCE}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "Big Screen Gaming",
                alias: "bigScreenGaming",
                type: "page",
                path: `/${ROUTES.GAMING_HIRE}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "Group Bookings",
                alias: "groupBooking",
                type: "modal",
                path: ROUTES.GROUP_SALE,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA]
            },
            {
                label: "Cinema Hire",
                alias: "cinemaHire",
                type: "modal",
                path: ROUTES.CINEMA_HIRE,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA]
            },
            {
                label: "History",
                alias: "history",
                type: "page",
                path: `/${ROUTES.HISTORY}`,
                enable: [COUNTRY.STA]
            },
            {
                label: "Advertising",
                alias: "advertising ",
                type: "page",
                path: `/${ROUTES.ADVERTISING}`,
                enable: [COUNTRY.STA]
            },
            {
                label: "Join Our Mailing List",
                alias: "joinOurMailListing ",
                type: "modal",
                path: ROUTES.MAILINGLIST,
                enable: [COUNTRY.STA]
            },
        ]
    },
    {
        header: {
            [COUNTRY.AUS]: "EXTRAS",
            [COUNTRY.NZ]: "EXTRAS",
            [COUNTRY.ANG]: "",
            [COUNTRY.STA]: "EXTRAS"
        },
        alias: "extras",
        menus: [
            {
                label: "About",
                alias: "about",
                type: "page",
                path: `/${ROUTES.ABOUT}`,
                enable: [COUNTRY.ANG]
            },
            {
                label: "Cinema Hire",
                alias: "cinemaHire",
                type: "page",
                path: `/${ROUTES.CINEMA_HIRE}`,
                enable: [COUNTRY.ANG]
            },
            {
                label: "Competitions",
                alias: "competitions",
                type: "page",
                path: `/${ROUTES.COMPETITIONS}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.ANG, COUNTRY.STA]
            },
            {
                label: "Kids Corner",
                alias: "kidsCorner",
                type: "page",
                path: `/${ROUTES.MOVIES}/${ROUTES.KIDS_NOW_SHOWING}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "Events",
                alias: "events",
                type: "page",
                path: `/${ROUTES.OFFERS_EVENTS}`,
                enable: [COUNTRY.STA]
            },
            {
                label: "Offers & Events",
                alias: "offersandEvents",
                type: "page",
                path: `/${ROUTES.OFFERS_EVENTS}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "Gift Shop",
                alias: "giftShop",
                type: "page",
                path: `/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.ANG, COUNTRY.STA]
            },
            {
                label: "Food & Beverage",
                alias: "foodandbeverage",
                type: "page",
                path: `/${ROUTES.FOOD_AND_BEVERAGE}`,
                enable: [COUNTRY.ANG]
            },
            {
                label: "High Line Terrace",
                alias: "highlineTrace",
                type: "page",
                path: `/${ROUTES.HIGHLINE_TERRACE}`,
                enable: [COUNTRY.ANG]
            },
            {
                label: "Reel Club",
                alias: "reelClub",
                type: "page",
                path: `/${ROUTES.REELCLUB}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "Membership",
                alias: "membership",
                type: "page",
                path: `/${ROUTES.MEMBERSHIP}`,
                enable: [COUNTRY.STA]
            },
            {
                label: "Angelika Rewards",
                alias: "rewards",
                type: "page",
                path: `/${ROUTES.REWARDS}`,
                enable: [COUNTRY.ANG]
            },
            // {
            //     label: "Memberships",
            //     alias: "memberships",
            //     type: "page",
            //     path: `/${ROUTES.MEMBERSHIPS}`,
            //     enable: [COUNTRY.ANG]
            // },
            {
                label: "Corporate Cinemoney",
                alias: "corporateCinemony",
                type: "modal",
                path: ROUTES.CINEMONY,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "State Bookstore",
                alias: "stateBookstore",
                type: "link",
                path: URLS.BOOK_STORE_STATE,
                enable: [COUNTRY.STA]
            },
            {
                label: "Café & Bar",
                alias: "cafeAndBar",
                type: "page",
                path: `/${ROUTES.CAFEBAR}`,
                enable: [COUNTRY.STA]
            },
            {
                label: "Offers",
                alias: "events",
                type: "page",
                path: `/${ROUTES.OFFERS_EVENTS}`,
                enable: [COUNTRY.ANG]
            },
            {
                label: "SoHo Lounge",
                alias: "sohoLounge",
                type: "page",
                path: `/${ROUTES.SOHOLOUNGE}`,
                enable: [COUNTRY.ANG]
            },
            {
                label: "Angelika Anywhere",
                alias: "angelikaAnywhere",
                type: "link",
                path: URLS.ANGELIKA_ANYWHERE_URL,
                enable: []
            },
        ]
    },
    {
        header: {},
        alias: "",
        menus: [
            {
                label: "Contact Us",
                alias: "contactUs",
                type: "modal",
                path: ROUTES.CONTACT_US,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.ANG, COUNTRY.STA]
            },
            {
                label: "FAQs",
                alias: "faq",
                type: "page",
                path: `/${ROUTES.FAQ}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.ANG, COUNTRY.STA]
            },
            {
                label: "Employment",
                alias: "employment",
                type: "modal",
                path: ROUTES.CAREER,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.ANG, COUNTRY.STA]
            },
            {
                label: "Ratings Index",
                alias: "ratingsIndex",
                type: "modal",
                path: ROUTES.RATING,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.ANG, COUNTRY.STA]
            },
            {
                label: "Terms",
                alias: "terms",
                type: "modal",
                path: ROUTES.TERMS,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA]
            },
            {
                label: " Site Disclaimer",
                alias: "siteDisclaimer",
                type: "modal",
                path: ROUTES.SITE_DISCLAIMER,
                enable: [COUNTRY.ANG]
            },
            {
                label: "Terms",
                alias: "terms",
                type: "modal",
                path: ROUTES.REELCLUB_TERMS,
                enable: [COUNTRY.ANG]
            },
            {
                label: "Privacy Policy",
                alias: "privacyPolicy",
                type: "modal",
                path: ROUTES.PRIVACY_POLICY,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.ANG, COUNTRY.STA]
            },
        ]
    },
]

export const AUTH_MENU: IAuthMenu = {
    userDetails: {
        enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA, COUNTRY.ANG]
    },
    loyalty: {
        enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA, COUNTRY.ANG]
    },
    upcomingShow: {
        enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA, COUNTRY.ANG]
    },
    purchaseHistorySection: {
        enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA, COUNTRY.ANG]
    },
    accountDetails: {
        enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA, COUNTRY.ANG]
    },
}

export const MOVIE_TYPES: IMOVIE_TYPES = {
    standard: {
        class: 'standard',
        title: 'Standard',
        active: false,
    },
    premium: {
        class: 'premium',
        title: 'Premium',
        active: false,
    },
    titanxc: {
        class: 'titanxc',
        title: 'Titan XC',
        active: false,
    },
    titanluxe: {
        class: 'titanluxe',
        title: 'Titan Luxe',
        active: false,
    },
    gold: {
        class: 'goldlounge',
        title: 'Gold Lounge',
        active: false,
    },
    soho: {
        class: 'soholounge',
        title: 'Soho',
        active: false,
    },
};

export const GENDER_LIST = [
    {
        value: 'Male',
        label: 'Male'
    },
    {
        value: 'Female',
        label: 'Female'
    },
    {
        value: 'Unknown',
        label: 'Other'
    }
]

export const GENDER_LIST_STA = [
    {
        value: 'Male',
        label: 'Man'
    },
    {
        value: 'Female',
        label: 'Woman'
    },
    {
        value: 'Non-Binary/Gender-Diverse',
        label: 'Non-Binary/Gender-Diverse'
    },
    {
        value: 'Unknown',
        label: 'Other'
    }
]

export const GENRE_LIST = [
    { value: 1, label: 'Action' },
    { value: 2, label: 'Adventure' },
    { value: 3, label: 'Comedy' },
    { value: 4, label: 'Documentary' },
    { value: 5, label: 'Family' },
    { value: 6, label: 'Horror' },
    { value: 7, label: 'Music' },
    { value: 8, label: 'Romance' },
    { value: 9, label: 'Sci-fi' },
    { value: 10, label: 'Thriller' }
]

export const MONTH_LIST = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
];

export const VACCINATION_LOCATIONS = [
    "auburn",
    "charlestow",
    "dubbo",
    "Maitland",
    "rhodes",
    "Rousehill",
    "Burwood",
    "chirnside",
    "dandenong",
    "epping",
    "Melton",
    "Sunbury",
    "waurnponds",
]

export const AES_KEY = 'reading';

export const SLICK_SETTING = {
    className: "filter-show-times",
    centerPadding: "10px",
    dots: false,
    lazyload: true,
    infinite: false,
    speed: 250,
    slidesToShow: 5,
    autoplay: false,
    cssEase: "linear",
    slidesToScroll: 5,
    slidesPerRow: 1,
    swipeToSlide: true,
    adaptiveHeight: true,
    arrows: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
    ],
};

export const SHOW_REWARDS = ['manville']

export const CONTACT_US_SEND_MAIL: any = {
    [COUNTRY.AUS]: [
        {
            value: 'cinema',
            label: 'Cinema',
            onSelect: 'cinema'
        },
        {
            value: 'gamingHires',
            label: 'Gaming Hires',
            onSelect: 'gaminghires'
        },
        {
            value: 'readingReelClub',
            label: 'Reading Reel Club',
            onSelect: 'reelclub'
        },
        {
            value: 'eTixSupport',
            label: 'E-Tix Support',
            onSelect: 'etix'
        },
        {
            value: 'giftShop',
            label: 'Gift Shop',
            onSelect: 'giftshop'
        },
        {
            value: 'headOffice',
            label: 'Head Office',
            onSelect: 'headoffice'
        }
    ],
    [COUNTRY.NZ]: [
        {
            value: 'cinema',
            label: 'Cinema',
            onSelect: 'cinema'
        },
        {
            value: 'gamingHires',
            label: 'Gaming Hires',
            onSelect: 'gaminghires'
        },
        {
            value: 'readingReelClub',
            label: 'Reading Reel Club',
            onSelect: 'reelclub'
        },
        {
            value: 'eTixSupport',
            label: 'E-Tix Support',
            onSelect: 'etix'
        },
        {
            value: 'giftShop',
            label: 'Gift Shop',
            onSelect: 'giftshop'
        },
        {
            value: 'headOffice',
            label: 'Head Office',
            onSelect: 'headoffice'
        }
    ],
    [COUNTRY.STA]: [

        {
            value: 'cinema',
            label: 'Cinema Manager',
            onSelect: 'cinema'
        },
        {
            value: 'eTixSupport',
            label: 'E-Tix Support',
            onSelect: 'etix'
        },
        {
            value: 'giftShop',
            label: 'Gift Shop',
            onSelect: 'giftshop'
        },
        {
            value: 'headOffice',
            label: 'Head Office',
            onSelect: 'headoffice'
        }

    ],
    [COUNTRY.ANG]: [
        {
            value: 'cinema',
            label: 'Cinema Manager',
            onSelect: 'cinema'
        },
        {
            value: 'eTixSupport',
            label: 'E-Tix Support',
            onSelect: 'etix'
        },
        {
            value: 'giftShop',
            label: 'Gift Shop',
            onSelect: 'giftshop'
        },
        {
            value: 'headOffice',
            label: 'Head Office',
            onSelect: 'headoffice'
        }

    ]
}

export const SCREEN_CATEGORY = {
    GOLD_LOUNGE: 'gold-lounge',
    PREMIUM: 'premium',
    TITAN_LUXE: 'titan-luxe',
    TITAN_XC: 'titan-xc',
    ALL: 'all-sessions'
};

export const CONTACT_US_ETIX_ERROR = [
    { value: "Movie Selection", label: "Movie Selection" },
    { value: "Ticket Type Selection", label: "Ticket Type Selection" },
    { value: "Seat Selection", label: "Seat Selection" },
    { value: "Login", label: "Login" },
    { value: "Payment", label: "Payment" },
    { value: "Confirmation", label: "Confirmation" },
    { value: "Print Ticket", label: "Print Ticket" },
]

export const TimeOfDayList = [
    { value: 'Morning', label: 'Morning' },
    { value: 'Afternoon', label: 'Afternoon' },
    { value: 'Evening', label: 'Evening' },
    { value: 'Late Evening', label: 'Late Evening' }
];

export const FLIM_TYPE = [
    { id: 1, label: 'Gold Lounge', flag: 'Gold', alias: 'gold' },
    { id: 2, label: 'Titan LUXE', flag: 'TitanLuxe', alias: 'titan-luxe' },
    { id: 3, label: 'Titan XC', flag: 'TitanXC', alias: 'titan-xc' },
    { id: 4, label: 'Premium', flag: 'Premium', alias: 'premium' },
    { id: 5, label: 'Standard', flag: 'Standard', alias: 'standard' }
]

export const PREMIUM_SERCHRG_ATTR: any = {
    [BRANDS.AU]: ['Soho']
}