import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";

export const RoktLauncherContext = createContext(null);

export function useRoktLauncher() {
  return useContext(RoktLauncherContext);
}

export interface IRoktLauncherContextProvider {
  children: ReactNode,
  accountId: string,
  sandbox: boolean,
}

export function RoktLauncherContextProvider({
  children,
  accountId,
  sandbox = false,
}: IRoktLauncherContextProvider) {
  const [launcher, setLauncher] = useState<any>(null);

  useEffect(() => {
    (async () => {
      // Guards against Rokt script being still loaded into the application when the context is created
      await new Promise((resolve: any) =>
      (window as any).Rokt
          ? resolve()
          : ((document.getElementById("rokt-launcher") as any).onload = resolve)
      );

      const launcherInstance = await (window as any).Rokt.createLauncher({
        accountId: accountId,
        sandbox: sandbox,
      });

      setLauncher(launcherInstance);
    })();

    return () => {
      if (launcher) {
        launcher.terminate();
      }
    };
  }, [accountId, sandbox]);

  // Return the context provider
  return (
    <RoktLauncherContext.Provider value={launcher}>
      {children}
    </RoktLauncherContext.Provider>
  );
}