import { Store } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { API_REQUEST_TYPE, URLS } from '../constants/url'
import { RootState } from '../store'
import { refreshTokenWithQueue } from './refreshToken'
import { setLoader } from '../store/application/action'

type SetupAxiosProps = (state: Store) => void

const setupAxiosInterceptors: SetupAxiosProps = (store) => {
  axios.defaults.baseURL = URLS.API_BASE_URL
  let loading = false
  let countRequest = 0;

  // Middleware to valid requests. Add token to request
  const onRequestSuccess = (config: any) => {

    countRequest = countRequest+1;

    if (!loading) {
      store.dispatch(setLoader(true));
    }
    const state: RootState = store.getState();
    const accessToken = state.applicationReducer.token;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    
    return config
  }
  axios.interceptors.request.use(onRequestSuccess, void 0)

  const onResponseSuccess = (config: any) => {
    countRequest = countRequest - 1;
    if (countRequest <= 0) {
      store.dispatch(setLoader(false));
    }
    return config
  }
  const onResponseFail = (error: any) => {
    const state: RootState = store.getState();
    const accessToken = state.applicationReducer.token;
    countRequest = countRequest - 1;
    const originalRequest = error.config
    if (countRequest <= 0) {
      countRequest = 0;
      store.dispatch(setLoader(false));
    }

    if (
      originalRequest &&
      error.code === AxiosError.ERR_NETWORK &&
      !originalRequest._retry &&
      originalRequest.url !== '/settings' &&
      accessToken
    ) {
      return refreshTokenWithQueue(originalRequest, null, store)
    }
    return Promise.reject(error)
  }
  axios.interceptors.response.use(onResponseSuccess, onResponseFail)
}

export default setupAxiosInterceptors
