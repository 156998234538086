import { IStripePaymentStatus } from "../../models/payment";
import axios from "axios";
import { ENDPOINT } from "../endpoint";
import { API_REQUEST_TYPE } from "../../constants/url";
import { COUNTRY, COUNTRY_CODE, CURRENCY, PAYMENT_PROVIDERS } from "../../constants/app";
import { EncryptionKeyResponse, MovieTransaction } from "../../models/payment"
import { MAKE_PAYMENT_API, REFUND_API } from "../../constants/payment";


export const orderContinueApi = (payload: any) => {
    const request = {
        countryId: payload.countryId,
        hasGL: payload.hasGL,
        requestType: API_REQUEST_TYPE.ORDER_CONTINUE,
        saleId: payload?.saleId,
        userSessionId: payload?.userSessionId,
    };
    return new Promise((resolve, reject) => {
        axios.post(ENDPOINT.ORDER_CONTINUE, request)
            .then((orderResponse: any) => {
                if (
                    orderResponse &&
                    orderResponse.data.statusCode == 200 &&
                    orderResponse.data.data &&
                    orderResponse.data.data.response &&
                    orderResponse.data.data.response.Result == 0
                ) {
                    return resolve({ status: true });
                } else {
                    return resolve({
                        status: false,
                        error: orderResponse?.data?.data?.error,
                    });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
};

export const makePaymentApi = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(MAKE_PAYMENT_API[payload.countryId as COUNTRY],
            payload
        )
            .then((response: any) => {
                if (
                    response &&
                    response.data &&
                    response.data.data &&
                    (response?.data?.data?.status === "Success" ||
                        response?.data?.data?.status === 0)
                ) {
                    return resolve({ status: true, data: response.data.data });
                } else {
                    return resolve({
                        status: false,
                        error: response?.data?.data?.responseDesc,
                    });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
};

export const completeOrderApi = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(ENDPOINT.COMPLETE_ORDER, payload)
            .then((res: any) => {
                if (
                    res &&
                    res.data &&
                    res.data.statusCode &&
                    res.data.statusCode == 200
                ) {
                    return resolve({
                        VistaBookingId: res.data.data.VistaBookingId,
                        status: true,
                    });
                } else {
                    return resolve({ status: false, error: "" });
                }
            })
            .catch((err) => {
                return resolve({ status: false, error: err });
            });
    });
};


export const fetchPaymentIntentClientSecret = (data: any) => {
    return new Promise(async (resolve, reject) => {
        return await axios.post(ENDPOINT.STRIPE_PAYMENT_INTENT, {
            amount: data.amount * 100,
            description: data.userSessionId,
            countryId: data.countryId,
        })
            .then((response: any) => {
                let { data } = response;
                if (data) {
                    const { data: intentData } = data;
                    return resolve(intentData);
                } else {
                    return resolve({ status: false, error: {} });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
};


export const fatzebraApplePaySessionApi = (payload: any) => {
    return new Promise(async (resolve, reject) => {
        return await axios.post(ENDPOINT.FATZEBRA_APPLE_SESSION,
            payload
        )
            .then((response: any) => {
                let { data } = response;
                if (data) {
                    return resolve(data);
                } else {
                    return resolve({ status: false, error: {} });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
};

export const refundPayment = (payload: any) => {
    return new Promise(async (resolve, reject) => {
        return await axios.post(REFUND_API[payload.countryId as COUNTRY],
            payload
        )
            .then((response: any) => {
                let { data } = response;
                if (data) {
                    return resolve({ status: true, data });
                } else {
                    return resolve({ status: false, error: {} });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
};

export const loyaltyPaymentApi = (payload:any)=>{
    return new Promise(async (resolve, reject) => {
        return await axios.post(ENDPOINT.LOYALTY_POINTS_PAYMENTS,
            payload
        )
            .then((response: any) => {
                let { data } = response;
                if (data) {
                    return resolve({ status: true, data });
                } else {
                    return resolve({ status: false, error: {} });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
}

//Gift card API

export const checkGiftCardBalanceApi = (payload: any) => {
    return new Promise(async (resolve, reject) => {
        return await axios.get(ENDPOINT.GIFTCARD_BALANCE_CHECK, {
            params: {
                cardNumber: payload.cardNumber,
                countryId: payload.countryId,
                requestType: API_REQUEST_TYPE.GIFTCARD_BALANCE_CHECK,
            },
        })
            .then((response: any) => {
                const {
                    data: { data },
                } = response;
                if (data.balance) {
                    return resolve({
                        status: true,
                        balance: data.balance,
                        expiry: data.expiry
                    });
                } else {
                    return resolve({
                        status: false,
                        error: data,
                    });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
};

export const makeGiftCardPaymentAPI = (payload: any) => {
    return new Promise(async (resolve, reject) => {
        return await axios.post(ENDPOINT.MAKE_GIFTCARD_PAYMENT, payload)
            .then((response: any) => {
                const {
                    data: { data },
                } = response;
                if (+data["result"] == 0) {
                    return resolve({ status: false });
                } else {
                    return resolve({ status: true, transactionCode: data[1] });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
};

export const refundGiftCardApi = (payload: any) => {
    return new Promise(async (resolve) => {
        return await axios.post(ENDPOINT.MAKE_GIFTCARD_REFUND, payload)
            .then((response: any) => {
                if (+response.result === 0) {
                    return resolve({ status: true });
                } else {
                    return resolve({ status: false });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
};

// Voucher
export const checkVoucherBalanceApi = (payload: any) => {
    const paymentDetails = {
        code: payload.cardNumber,
        requestType: "voucherPayment",
        location: payload.theatreId.toLowerCase(),
    };

    return new Promise(async (resolve, reject) => {
        return await axios.post(ENDPOINT.VOUCHER_BALANCE_CHECK, paymentDetails)
            .then((response: any) => {
                const {
                    data: { data },
                } = response;
                if (data.balance) {
                    return resolve({
                        status: true,
                        balance: data.balance,
                    });
                } else {
                    return resolve({
                        status: false,
                        error: data,
                    });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
};

export const makeVoucherPaymentAPI = (payload: any) => {
    return new Promise(async (resolve, reject) => {
        return await axios.post(ENDPOINT.MAKE_VOUCHER_PAYMENT, payload)
            .then((response: any) => {
                const {
                    data: {
                        data: { result, transactionCode },
                    },
                } = response;
                if (+result === 0) {
                    return resolve({ status: false, error: "" });
                } else {
                    return resolve({ status: true, transactionCode: transactionCode });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
};

export const refundVoucherApi = (payload: any) => {
    return new Promise(async (resolve) => {
        return await axios.post(ENDPOINT.MAKE_VOUCHER_REFUND, payload)
            .then((response: any) => {
                return resolve({ status: true });
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
};


// Gift shop API
export const giftCardOrderApi = (payload: any) => {
    return new Promise(async (resolve) => {
        return await axios.post(ENDPOINT.GIFTCARD_ORDER, payload)
            .then((response: any) => {
                const { data: { statusCode } } = response
                if (statusCode === 200) {
                    return resolve({ status: true, data: { cinemaId: payload.cinemaId, countryId: payload.countryId, userSessionId: payload.userSessionId } });
                } else {
                    return resolve({ status: false, error: response?.data?.message });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
};

export const giftCardOrderContinueAPI = (payload: any) => {
    return new Promise(async (resolve) => {
        return await axios.post(ENDPOINT.GIFTCARD_ORDER_CONTINUE, payload)
            .then((response: any) => {
                const { data: { message: { status, data: { cinemaId, countryId, uniqueKey } }, statusCode } } = response
                if (statusCode === 200 && status === 200) {

                    return resolve({ status: true, data: { cinemaId, countryId, userSessionId: uniqueKey } });
                } else {
                    return resolve({ status: false, error: response?.data?.message });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
};

export const giftCardMakePaymentApi = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(MAKE_PAYMENT_API[payload.countryId as COUNTRY],
            payload
        )
            .then((response: any) => {
                if (
                    response &&
                    response.data &&
                    response.data.data &&
                    (response?.data?.data?.status === "Success" ||
                        response?.data?.data?.status === 0)
                ) {
                    return resolve({ status: true, data: response.data.data });
                } else {
                    return resolve({
                        status: false,
                        error: response?.data?.data?.responseDesc,
                    });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
}

export const giftCardCompleteOrderApi = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(ENDPOINT.GIFTCARD_COMPLETE_ORDER, payload)
            .then((res: any) => {
                if (
                    res &&
                    res.data &&
                    res.data.statusCode &&
                    res.data.statusCode == 200
                ) {
                    return resolve({
                        VistaBookingId: res.data.data.VistaBookingId,
                        status: true,
                    });
                } else {
                    return resolve({ status: false, error: "" });
                }
            })
            .catch((err) => {
                return resolve({ status: false, error: err });
            });
    });
};

export const refundGiftCardPaymentApi = (payload: any) => {
    return new Promise(async (resolve, reject) => {
        return await axios.post(
            (payload.countryId === COUNTRY.AUS || payload.countryId === COUNTRY.STA || payload.countryId === COUNTRY.ANG) ? ENDPOINT.FATZEBRA_REFUND : payload.countryId === COUNTRY.US ? ENDPOINT.PAYEEZY_REFUND : ENDPOINT.STRIPE_REFUND,
            payload
        )
            .then((response: any) => {
                let { data } = response;
                if (data) {
                    return resolve({ status: true });
                } else {
                    return resolve({ status: false, error: {} });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
}

export const sendMailApi = (payload: any) => {
    return new Promise(async (resolve, reject) => {
        return await axios.post(ENDPOINT.MAIL_LIBRARY, payload
        )
            .then((response: any) => {
                const { data: { errorMessage } } = response;
                if (errorMessage) {
                    return resolve({ status: false, error: errorMessage });
                } else {
                    return resolve({ status: true });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
}

export const memberValidationAPI = (payload: any) => {
    return new Promise(async (resolve, reject) => {
        return await axios.post(ENDPOINT.MEMEBER_VALIDATE, payload
        )
            .then((response: any) => {
                if (response) {
                    return resolve({ status: true, response });
                } else {
                    return resolve({ status: false });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
}

export const getCreditCardEncryptionKey = (data: MovieTransaction, callBack: (response: EncryptionKeyResponse | null) => void) => {

    axios.post(ENDPOINT.GET_ENCRYPT_KEY, data)
        .then((response: any) => {

            if (response.data && response.data.statusCode && response.data.statusCode == 200) {
                callBack(response.data as EncryptionKeyResponse)
            } else {
                callBack(null)
            }
        }).catch((error: any) => {
            callBack(null)
        })
}

export const generateMailApi = (payload: {
    id: any;
    countryId: string;
    templateName: string;
}) => {
    return new Promise(async (resolve, reject) => {
        return await axios.post(ENDPOINT.MAIL_LIBRARY, payload
        )
            .then((response: any) => {
                if (response) {
                    return resolve({ status: true, response });
                } else {
                    return resolve({ status: false });
                }
            })
            .catch((error: any) => {
                return resolve({ status: false, error });
            });
    });
}

export const verifyFatzebraPaymentTokenApi = (payload: any) => {
    return axios.post(
        (payload.countryId === COUNTRY.AUS || payload.countryId === COUNTRY.STA || payload.countryId === COUNTRY.ANG) ? ENDPOINT.VERIFY_FATZEBRA_TOKEN : ENDPOINT.VERIFY_PAYEEZY_TOKEN,
        payload);
}

export const getPaymentMethod = (countryId: string) => {
    return countryId === COUNTRY.NZ ? PAYMENT_PROVIDERS.STRIPE : countryId === COUNTRY.US ? PAYMENT_PROVIDERS.PAYEEZY : PAYMENT_PROVIDERS.FATZEBRA;
}

export const checkRefundStatusApi = (payload: any) => {
    return axios.post(ENDPOINT.CHECK_REFUND_STATUS, payload);
}

export const makePartialRefundApi = (payload: any) => {
    return axios.post(ENDPOINT.CHECK_REFUND_STATUS, payload);
}