import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import MovieDescription from "../../../../../components/TicketBooking/MovieDescription";
import { generateMailApi } from "../../../../../services/payment";
import { toast } from "react-toastify";
import { concessionPriceCalculation } from "../../../../../utils/tickets";
import { orderHistoryApi } from "../../../../../services/concessions";
import { API_REQUEST_TYPE, IMAGE_URL_CONFIG, URLS } from "../../../../../constants/url";
import Barcode from "react-barcode";
import "./ticketConfirmation.scss";
import { AES_KEY, BRANDS, COUNTRY } from "../../../../../constants/app";
import FutureFb from "../Concession/FutureFb";
import moment from "moment";
import { useLocation, useNavigate } from "react-router";
import { AES } from "crypto-js";
import { downloadFile, encryptData } from "../../../../../utils/helper";
import { useRoktLauncher } from "../../../../../rokt";
import { clearFoodItemsAction } from "../../../../../store/foodAndBeverage/action";
import { toggleTicketModal } from "../../../../../store/ticketBooking/action";
import { ROUTES } from "../../../../../constants/path";
import { IFormatedTicketType } from "../../../../../models/tickets";
import OrderHistory from "../Concession/FutureFb/OrderHistory";
import { walletPassApi } from "../../../../../services/auth";

interface ITicketConfirmationProps {
  onCloseModal: () => void;
}

const TicketConfirmation: FC<ITicketConfirmationProps> = ({ onCloseModal }) => {
  const {
    ticketLessFb,
    brandId,
    currentCinema,
    films,
    modal,
    ticketDetails,
    countryId,
    orderCartData,
    isFutureFb,
    preOrderFb,
    seatSwapDetails,
    userDetails,
    prePurchasedTicket,
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    brandId: state.applicationReducer.brandId,
    films: state.ticketBookingReducer.films,
    currentCinema: state.applicationReducer.currentCinema,
    ticketLessFb: state.foodBeverageReducer.ticketLessFb,
    modal: state.ticketBookingReducer.modal,
    ticketDetails: state.ticketBookingReducer.ticketDetails,
    orderCartData: state.foodBeverageReducer.orderCartData,
    isFutureFb: state.ticketBookingReducer.isFutureFb,
    preOrderFb: state.ticketBookingReducer.preOrderFb,
    seatSwapDetails: state.ticketBookingReducer.seatSwapDetails,
    userDetails: state.authReducer.userDetails,
    prePurchasedTicket: state.foodBeverageReducer.prePurchasedTicket,
  }));

  const navigator = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isSeatSwap = useMemo(() => {
    return pathname.includes("/seat-swap/") && seatSwapDetails ? true : false;
  }, [pathname, seatSwapDetails]);
  const launcher = useRoktLauncher();
  const [historyData, setHistory] = useState<any>([]);

  const sendFBTicketConfirmation = async (conData: any) => {
    const payload = {
      id: preOrderFb.saleId,
      countryId: countryId,
      templateName: "E-Tix Confirmation",
      concessionData: conData
    };
    const { status } = (await generateMailApi(payload)) as any;
    if (status) {
      toast.success("Confirmation sent to your e-mail successfully.");
    } else {
      toast.error(
        "Could not connect to services at the moment. Confirmation mail will be sent to your e-mail shortly."
      );
    }
  };

  const sendTicketConfirmation = async () => {
    const payload = {
      id: ticketDetails?.userSessionId,
      countryId: countryId,
      templateName: "E-Tix Confirmation",
      swappedTicket: isSeatSwap,
    };
    const { status } = (await generateMailApi(payload)) as any;
    if (status) {
      toast.success("Confirmation sent to your e-mail successfully.");
    } else {
      toast.error(
        "Could not connect to services at the moment. Confirmation mail will be sent to your e-mail shortly."
      );
    }
  };
  const getOrderHistory = async () => {
    const history = await orderHistoryApi({
      requestType: API_REQUEST_TYPE.GET_ORDER_HISTORY,
      parentId: prePurchasedTicket.id,
      countryId: countryId,
    });
    if (history.data) {
      let conData: any = [];

      history.data.data.forEach((element: any) => {
        element.Extra.forEach((ele: any) => {
          conData.push(JSON.parse(ele));
        })
      });
      sendFBTicketConfirmation(conData);
      setHistory(history.data.data);
    }
  };

  useEffect(() => {
    if(!ticketLessFb){
      sendTicketConfirmation();
    }
    getOrderHistory();

    if (!launcher) {
      return;
    }

    const selectionPromise = (launcher as any).selectPlacements({
      attributes: {
        email: userDetails?.email,
        amount: (
          (ticketDetails.total ? ticketDetails.total : 0) + concessionTotal
        ).toFixed(2),                
        confirmationref: ticketDetails?.bookingId,
        price: (
          (ticketDetails.total ? ticketDetails.total : 0) + concessionTotal
        ).toFixed(2),
        firstname: userDetails?.firstName,
        lastname: userDetails?.lastName,
        mobile: userDetails?.phoneNumber
      },
      //identifier: "confirmation.page",
    });

    return () => {
      if (selectionPromise) {
        // When the page closes, remove all the Rokt placements
        selectionPromise.then((selection: any) => selection.close());
      }
    };
  }, []);

  const concessionTotal = useMemo(() => {
    return concessionPriceCalculation(orderCartData);
  }, [orderCartData]);

  const total = useMemo(() => {
    return (
      (ticketDetails.total ? ticketDetails.total : 0) + concessionTotal
    ).toFixed(2);
  }, [ticketDetails, concessionTotal]);

  // this.fbOrderHistory = orders.data.map(v=>{
  //   return {
  //     createdOn: moment.parseZone(v.Created_date).format('hh:mm A, ddd D MMMM YYYY'),
  //     concessionTotal: v.Extra?v.Extra.map(v=>JSON.parse(v)).reduce((acc, v)=>{
  //       return acc +  Number(v.Price);
  //     },0):0,
  //     concessions: v.Extra?v.Extra.map(v=>JSON.parse(v)).map(v=>{
  //       const smartModifierItem =
  //       (v.smartModifierGroups && v.smartModifierGroups.length > 0)? (v.smartModifierGroups[0].selected):[];
  //       v.modifierItem  = [...v.modifier,...smartModifierItem].join(',');
  //       return v;
  //     }):[],
  //     id:v.Id
  //   }
  // })

  const openRefund = () => {
    const encrypt = encryptData(ticketDetails.userSessionId, AES_KEY);
    navigator(`/${currentCinema.slug}/refund/${encodeURIComponent(encrypt)}`);
  };

  const openSeatSwap = () => {
    const encrypt = encryptData(ticketDetails.userSessionId, AES_KEY);
    navigator(
      `/${currentCinema.slug}/seat-swap/${encodeURIComponent(encrypt)}`
    );
  };

  const Banner = () => {
    return (
      <div className="col-12 col-md-12 pl-lg-0 ">
        <div className="swap-banner">
          <p>
            Your refund total will be returned to the original form of payment.
          </p>
          <p className="italic">
            Please allow up to 72 business hours for the funds to reflect in
            your account.
          </p>
        </div>
      </div>
    );
  };

  const onReturn = () => {
    dispatch(clearFoodItemsAction());
    dispatch(toggleTicketModal(false));
    navigator(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`)
  }

  const totalTicketQty = useMemo(() => {
    if (ticketDetails?.ticketTypes) {
      return ticketDetails?.ticketTypes.reduce((acc: number, v: IFormatedTicketType) => {
        acc = acc + v.qty
        return acc;
      }, 0)
    } else {
      return 0
    }
  }, [ticketDetails])


  const googleWalletUrl = useMemo(()=>{
    const data = encryptData(
        JSON.stringify({
          id: userDetails?.result?.memberRefId!,
          emailId: userDetails?.email!,
          type: API_REQUEST_TYPE.LOYALTY,
          wallet: "google",
          countryId: countryId,
          device:'web'
        }),
        process.env.REACT_APP_WALLET_PASS_KEY!
    );
    return `${URLS.API_BASE_URL}/applewallet/getpass?id=${encodeURIComponent(data)}`;
  },[userDetails])


  const appleWalletUrl = useMemo(()=>{
    const data = encryptData(
        JSON.stringify({
          id: userDetails?.result?.memberRefId!,
          emailId: userDetails?.email!,
          type: API_REQUEST_TYPE.LOYALTY,
          wallet: "apple",
          countryId: countryId,
          device:'web'
        }),
      process.env.REACT_APP_WALLET_PASS_KEY!
    );
    return `${URLS.API_BASE_URL}/applewallet/getpass?id=${encodeURIComponent(data)}`;
  },[userDetails])


  return (
    <div className="row ticket-confirmation-wrapper">
      {!ticketLessFb ? <MovieDescription enableShare={true} /> : null}
      <div className={`${ticketLessFb ? "col-12" : "col-md-8 px-2 py-5"}`}>
        <div className="form_page">
          <div className="ticket-confirm custom_scroll_content vscroll_600">
            {!ticketLessFb ? (
              <div className="ticket-header">Enjoy your movie!</div>
            ) : null}
            {ticketLessFb ? (
              <>
                <div className="ticket-header">Enjoy your food!</div>
                <br />
                <p>
                  Your order is being prepared, you will receive a sms once it
                  is ready to collect.
                </p>
              </>
            ) : null}

            <div className="ticket_detail_wrap">
              <div className="ticket_detail">
                <div className="ticket_number">
                  <p className="label">ORDER NUMBER</p>
                  <p className="number">{ticketDetails?.bookingId}</p>
                </div>
              </div>

              <div className="bar_code">
                <Barcode
                  width={2}
                  height={80}
                  displayValue={true}
                  value={
                    ticketDetails?.bookingId ? ticketDetails?.bookingId : ""
                  }
                />
              </div>
            </div>
            {!ticketLessFb ?
              <div className="add-to-wallet-ticket">
                <p>Add ticket(s) to:</p>
                <div className="wallet-button">
                <div className="google-wallet-btn"><a href={googleWalletUrl} target="_blank"><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'google_wallet.svg'} /></a></div>
                  <div className="apple-wallet-btn"><a href={appleWalletUrl} target="_blank"><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'apple_wallet.svg'} /></a></div>
                </div>
              </div>
              : null}
            {isFutureFb && ticketLessFb ? (
              <>
              <table className="movie_details">
                <tbody>
                  <tr>
                    <th scope="row">Movie</th>
                    <td> {prePurchasedTicket?.movieName}</td>
                  </tr>
                  <tr>
                    <th>Session</th>
                    <td>
                      {prePurchasedTicket?.date} at {prePurchasedTicket?.time}
                    </td>
                  </tr>
                  <tr>
                    <th>Location</th>
                    <td style={{ textTransform: "capitalize" }}>
                      {prePurchasedTicket?.cinemaName}
                    </td>
                  </tr>
                  <tr>
                    <th>Seats</th>
                    <td style={{ textTransform: "capitalize" }}>
                      {prePurchasedTicket.selectedSeats
                        ? prePurchasedTicket.selectedSeats
                        : "---"}
                    </td>
                  </tr>
                  {prePurchasedTicket.concessions.length > 0 ? (
                    <tr>
                      <th>Food & Beverages</th>
                      <td>
                        {prePurchasedTicket.concessions.map(
                          (foodItems: any) => (
                            <div className="mb-2">
                              {foodItems.Description}
                              {foodItems.modifierItem}
                              <br />
                              Quantity: {foodItems.Quantity} -{" "}
                              {foodItems.Delivery}
                            </div>
                          )
                        )}
                      </td>
                      <td>
                        {orderCartData.map((foodItems: any) => (
                          <div className="mb-2">
                            {foodItems.description}
                            {foodItems.variant && foodItems.variant._comment
                              ? " - " + foodItems.variant._comment
                              : ""}
                            <br />
                            Quantity: {foodItems.quantity} -{" "}
                            {foodItems.delivery === "self"
                              ? "Self Collection"
                              : "Seat Delivery"}
                          </div>
                        ))}
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <th>Total Paid </th>
                    <td>
                      {/* {(ticketDetails.total+prePurchasedTicket.ticketPrice+prePurchasedTicket.concessionTotal)} */}
                    </td>
                  </tr>
                </tbody>
              </table>
              <OrderHistory historyData={historyData}></OrderHistory>
            </>
            ) : (
              <table className="movie_details">
                <tbody>
                  {!ticketLessFb ? (
                    <>
                      {" "}
                      <tr>
                        <th scope="row">Movie</th>
                        <td> {films?.movieName}</td>
                      </tr>
                      <tr>
                        <th>Session</th>
                        <td>
                          {films
                            ? moment
                                .parseZone(films?.showDateTime)
                                .format("dddd Do MMMM YYYY")
                            : ""}{" "}
                          {films
                            ? moment
                                .parseZone(films?.showDateTime)
                                .format("hh:mm A")
                            : ""}
                        </td>
                      </tr>
                    </>
                  ) : null}
                  <tr>
                    <th>Location</th>
                    <td style={{ textTransform: "capitalize" }}>
                      {currentCinema?.cinemaName}
                    </td>
                  </tr>
                  {ticketDetails?.selectedSeats?.length! > 0 &&
                  !ticketLessFb ? (
                    <tr>
                      <th>Seats</th>
                      <td>
                        {ticketDetails?.selectedSeats?.length! > 0
                          ? ticketDetails?.selectedSeats
                              ?.map((v: any) => v.SeatId)
                              .join(", ")
                          : ""}
                          <span
                            onClick={openSeatSwap}
                            className="seat-swap-btn"
                          >
                            Change Seats
                          </span>
                      </td>
                    </tr>
                  ) : null}
                  {(countryId === COUNTRY.STA || countryId === COUNTRY.ANG) &&
                  !ticketLessFb ? (
                    <tr>
                      <th>Screen</th>
                      <td>{films.screenName}</td>
                    </tr>
                  ) : null}
                  {!ticketLessFb ? <tr>
                    <th>
                      <span>
                      Ticket Price
                      </span>
                    </th>
                    <td>
                    <span>${Number(ticketDetails.total ? ticketDetails.total : 0).toFixed(2)}</span>

                    {ticketDetails?.ticketTypes?.map(
                        (v: IFormatedTicketType) =>
                          v.qty > 0 ? (
                            <div>
                              <div className="ticket-desc">
                                {v.qty} x {v.description} seats: $
                                {(v.qty * v.price).toFixed(2)}
                              </div>
                            </div>
                          ) : null
                      )}
                      {totalTicketQty > 0 ? <div className="ticket-desc">
                        <span>{totalTicketQty} x BOOKING FEE: ${ticketDetails?.bookingFees.toFixed(2)}</span>
                      </div> : null}
                    </td>
                  </tr>:null}

                  {orderCartData.length > 0 ? (
                    <tr>
                      <th>Food & Beverages</th>
                      <td>
                        {orderCartData.map((foodItems: any) => (
                          <div className="mb-2">
                            {foodItems.description}
                            {foodItems.variant && foodItems.variant._comment
                              ? " - " + foodItems.variant._comment
                              : ""}
                            <br />
                            Quantity: {foodItems.quantity} -{" "}
                            {foodItems.delivery === "self"
                              ? "Self Collection"
                              : "Seat Delivery"}
                          </div>
                        ))}
                      </td>
                    </tr>
                  ) : null}
                  {concessionTotal > 0?<tr>
                    <th>
                      <span>
                        Food Price
                      </span>
                    </th>
                    <td>
                      <span>${Number(concessionTotal ? concessionTotal : 0).toFixed(2)}</span>
                    </td>
                  </tr>:null}
                  <tr>
                    <th>
                      <span>
                        TOTAL PAID
                      </span>
                    </th>
                    <td>
                      <span>${Number(total ? total : 0).toFixed(2)}</span>{" "}
                      {!ticketLessFb?<span onClick={openRefund} className="refund-btn">
                        Request Refund
                      </span>:null}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          {isSeatSwap ? <Banner /> : null}
          <div className={`movie_footer_btn_el col-md-12 ml-auto`}>
            <div className="movie_button_wrap justify-content-end">
              <button
                type="button"
                className="btn black_btn"
                onClick={onReturn}
              >
                RETURN TO HOME SCREEN
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="rokt-placeholder" />
    </div>
  );
};

export default TicketConfirmation;
