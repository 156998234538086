import axios from 'axios';
import { ENDPOINT } from '../endpoint';

export const signUpApi = (payload: any) => {
    return axios.post(`${ENDPOINT.SIGN_UP}`, payload).then(response => ({ status: true, response: response })).catch(error => ({ status: false, error }))
}

export const signInApi = (payload: any) => {
    return axios.post(`${ENDPOINT.SIGN_IN}`, payload).then(response => ({ status: true, response: response })).catch(error => ({ status: false, error }))
}

export const memberValidationApi = (payload: any) => {
    return axios.post(`${ENDPOINT.MEMEBER_VALIDATE}`, payload).then(response => ({ status: true, response: response })).catch(error => ({ status: false, error }))
}

export const memberUpdateApi = (payload: any) => {
    return axios.post(`${ENDPOINT.MEMBER_UPDATE}`, payload).then(response => ({ status: true, response: response })).catch(error => ({ status: false, error }))
}

export const memberAtrributeUpdateApi = (payload: any) => {
    return axios.post(`${ENDPOINT.MEMBER_UPDATE}`, payload).then(response => ({ status: true, response: response })).catch(error => ({ status: false, error }))
}

export const updateMemberAttributeApi = (payload: any) => {
    return axios.post(`${ENDPOINT.MEMBER_UPDATE_ATTRIBUTE}`, payload).then(response => ({ status: true, response: response })).catch(error => ({ status: false, error }))
}

export const getMemberCardlessApi = (payload: any) => {
    return axios.post(`${ENDPOINT.MEMBER_CARDLESS}`, payload).then(response => ({ status: true, response: response })).catch(error => ({ status: false, error }))
}

export const forgotPasswordApi = (payload: any) => {
    return axios.post(`${ENDPOINT.FORGOT_PASSWORD}`, payload).then(response => ({ status: true, response: response })).catch(error => ({ status: false, error }))
}

export const upcomingTicketApi = (payload: { countryId: string, emailId: string, requestType: string }) => {
    return axios.post(`${ENDPOINT.UPCOMING_TICKETS}`, payload).then(response => ({ status: true, response })).catch(error => ({ status: false, error }))
}

export const purchaseHistoryApi = (payload: any) => {
    return axios.post(`${ENDPOINT.TRANSACTION_HISTORY}`, payload);
}

export const walletPassApi = (payload: any) => {
    return axios.post(`${ENDPOINT.GENERATE_PASS}`, payload)
}

export const getSavedCardApi = (payload: { memberRefId: string, countryId: string, emailId: string }) => {
    return axios.post(`${ENDPOINT.SAVED_CARDS}`, payload).then(response => ({ status: true, response })).catch(error => ({ status: false, error }))
}

export const redeemPointsApi = (payload: { cardNumber: string, countryId: string }) => {
    return axios.post(`${ENDPOINT.ACCOUNT_MEMBER_ITEMS}`, payload)
}

export const changePasswordApi = (payload: any) => {
    return axios.post(`${ENDPOINT.CHANGE_PASSWORD}`, payload)
}

export const updateProfileApi = (payload: any) => {
    return axios.post(`${ENDPOINT.ACCOUNT_MEMBER_ITEMS}`, payload)
}

export const lostCardApi = (payload: any) => {
    return axios.post(`${ENDPOINT.LOST_CARD}`, payload)
}

export const removedCard = (payload: { memberRefId: string, token: string, countryId: string, emailId: string }) => {
    return axios.post(`${ENDPOINT.ACCOUNTS_REMOVE_SAVE_CARD}`, payload)
}

export const getMakeDefaultCardApi = (payload: { memberRefId: string, token: string, countryId: string, emailId: string }) => {
    return axios.post(`${ENDPOINT.ACCOUNT_MAKE_DEFAULT}`, payload)
}

export const updateSavedCardApi = (payload: { emailId: string, token: string, countryId: string, expirymonth: string, expiryyear: string, name: string, memberRefId: string }) => {
    return axios.post(`${ENDPOINT.ACCOUNT_UPDATE_CARD}`, payload)
}

export const updateCardFatzebraApi = (payload: { emailId: string, token: string, countryId: string, expirymonth: string, expiryyear: string, name: string, memberRefId: string }) => {
    return axios.post(`${ENDPOINT.PAYMENT_CARDS_UPDATE}`, payload)
}

export const saveCardApi = (payload: { countryId: string, cvcnumber: string, emailId: string, expirymonth: string, expiryyear: string, memberRefId: string, token: string, name: string, number: string, url: string }) => {
    return axios.post(`${ENDPOINT.PAYMENT_CARDS}`, payload)
}

export const logOutApi = () => {
    return axios.post(`${ENDPOINT.LOGOUT}`, {});
}

export const newsLetterApi = (payload: any) => {
    return axios.post(`${ENDPOINT.NEWS_LETTER_SIGNUP}`, payload)
}

export const resetConfirmationApi = (payload: any) => {
    return axios.post(`${ENDPOINT.RESET_CONFIRMATION}`, payload)
}

export const resetPasswordApi = (payload: any) => {
    return axios.post(`${ENDPOINT.RESET_PASSWORD}`, payload)
}

export const userConfirmationApi = (payload: any) => {
    return axios.post(`${ENDPOINT.USER_CONFIRMATION}`, payload)
}

export const checkMembershipBalanceApi = (payload: { cardNumber: string, countryId: string, guestCardUpdate: boolean }) => {
    return axios.post(`${ENDPOINT.MEMEBER_VALIDATE}`,
        {
            cardNumber: payload.cardNumber,
            countryId: payload.countryId,
            guestCardUpdate: payload.guestCardUpdate,
        }
    )
}

export const getMembershipPackageApi = (payload: { cinemaId: string, countryId: string }) => {
    return axios.post(`${ENDPOINT.GET_MEMBERSHIP_PACKAGE}`, payload)
}

export const getMemberItemsApi = (payload: { countryId: string, MemberRefId: string, cardNumber: string, isMemberPackage: boolean }) => {
    return axios.post(`${ENDPOINT.GET_MEMBER_ITEMS}`, payload)
}

export const getSavedGiftcardApi = (payload: {emailId: string, memberRefId: string, cardNumber: string, NumberLastDigits: string, status: string, countryId: string}) => {
    return axios.post(`${ENDPOINT.GET_SAVED_GIFTCARD}`, payload).then(response => ({ status: true, response })).catch(error => ({ status: false, error }))
}

export const savedGiftcardApi = (payload: {emailId: string, memberRefId: string, cardNumber: string, countryId: string}) => {
    return axios.post(`${ENDPOINT.SAVED_GIFTCARD}`, payload)
}

export const removedGiftCardApi = (payload: {emailId: string, memberRefId: string, cardNumber: string, countryId: string}) => {
    return axios.post(`${ENDPOINT.REMOVE_SAVED_GIFTCARD}`, payload)
}

export const getLoyaltyPointsApi = (payload: any)=>{
    return axios.post(ENDPOINT.GET_LOYALTY_POINTS, payload)
}

export const updateRenewalTypeApi = (payload:any)=>{
    return axios.post(ENDPOINT.UPDATE_RENEWAL_TYPE, payload)
}

export const getRenewalInfoApi = (payload:any)=>{
    return axios.post(ENDPOINT.GET_RENEWAL_INFO, payload)
}