import { ACTIONS } from "./type";


export function signUpAsGuest(payload: any, resolve: any, reject: any) {
    return { type: ACTIONS.SIGN_UP_AS_GUEST, payload, resolve, reject };
}

export function signUpSuccess(payload: any) {
    return { type: ACTIONS.SIGN_UP_SUCCESS, payload: payload };
}

export function signUpError(error: any) {
    return { type: ACTIONS.SIGN_UP_ERROR, payload: error };
}

export function signInWatcher(payload: any, resolve: any, reject: any) {
    return { type: ACTIONS.SIGN_IN_WATCHER, payload, resolve, reject };
}

export function signInSuccess(payload: any) {
    return { type: ACTIONS.SIGN_IN_SUCCESS, payload: payload };
}

export function signInError(error: any) {
    return { type: ACTIONS.SIGN_IN_FAILURE, payload: error };
}

export function memberValidationWatcher(payload: any, resolve?: any, reject?: any) {
    return { type: ACTIONS.MEMBER_VALIDATION_WATCHER, payload, resolve, reject };
}

export function memberValidationSuccess(payload: any) {
    return { type: ACTIONS.MEMBER_VALIDATION_SUCCESS, payload: payload };
}

export function memberValidationError(error: any) {
    return { type: ACTIONS.MEMBER_VALIDATION_FAILURE, payload: error };
}

export function showLogin(payload: boolean) {
    return { type: ACTIONS.SHOW_LOGIN, payload: payload };
}

export function forgotPasswordWatcher(payload: any, resolve: any, reject: any) {
    return { type: ACTIONS.FORGOT_PASSWORD_WATCHER, payload, resolve, reject };
}

export function forgotPasswordSuccess(payload: any) {
    return { type: ACTIONS.FORGOT_PASSWORD_SUCCESS, payload: payload };
}

export function forgotPasswordError(error: any) {
    return { type: ACTIONS.FORGOT_PASSWORD_FAILURE, payload: error };
}

export function setUserDetails(payload: any) {
    return { type: ACTIONS.SET_USER_DETAILS, payload: payload }
}

export function logoutAction(payload: any) {
    return { type: ACTIONS.SET_LOGOUT, payload: payload }
}

export function getSavedCardWatcher(data: any) {
    return { type: ACTIONS.GET_SAVED_CARDS_WATCHER, payload: data };
}

export function getSavedCardSuccess(payload: any) {
    return { type: ACTIONS.GET_SAVED_CARDS_SUCCESS, payload: payload };
}

export function getSavedCardError(error: any) {
    return { type: ACTIONS.GET_SAVED_CARDS_ERROR, payload: error };
}

export function showReelClubModal(payload: boolean) {
    return { type: ACTIONS.SHOW_REEL_CLUB_MODAL, payload: payload };
}

export function showUpdateProfileModal(payload: boolean) {
    return { type: ACTIONS.SHOW_UPDATE_PROFILE_MODAL, payload: payload };
}

export function showPhysicalCardModal(payload: boolean) {
    return { type: ACTIONS.SHOW_PHYSICAL_CARD_MODAL, payload: payload };
}

export function showManageCardModal(payload: boolean) {
    return { type: ACTIONS.SHOW_MANAGE_CARD_MODAL, payload: payload };
}

export function showManageGiftCardModal(payload: boolean) {
    return { type: ACTIONS.SHOW_MANAGE_GIFT_CARD_MODAL, payload: payload };
}

export function updateMembbershipPurchase(payload: any){
    return { type: ACTIONS.MEMBERSHIP_PURCHASE, payload:payload}
}

export function getSavedGiftCardsWatcher(payload: any){
    return { type: ACTIONS.GET_SAVED_GIFT_CARDS_WATCHER, payload }
}

export function getSavedGiftCardsSuccess(payload: any) {
    return { type: ACTIONS.GET_SAVED_GIFT_CARDS_SUCCESS , payload };
}

export function getSavedGiftCardsError(payload: any) {
    return { type: ACTIONS.GET_SAVED_GIFT_CARDS_ERROR, payload };
}

export function getRenewalInfo(payload: any){
    return { type: ACTIONS.GET_RENEWAL_INFO, payload };
}

export function getRenewalInfoSuccess(payload: any){
    return { type: ACTIONS.RENEWAL_INFO_SUCCESS, payload };
}

export function getRenewalInfoError(payload: any){
    return { type: ACTIONS.RENEWAL_INFO_ERROR, payload };
}