import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { concessionPriceCalculation } from "../../../../utils/tickets";
import CreditCard, { ICardRef } from "../CreditCard";
import { checkGiftCardBalanceApi } from "../../../../services/payment";
import TextInput from "../../../UI/TextInput";
import { numberOnly } from "../../../../utils/helper";
import "./giftcard.scss";
import { getSavedGiftCardsWatcher } from "../../../../store/auth/action";
import { GiftCardDetail } from "../../../../models/common";
import { BRANDS } from "../../../../constants/app";
import { removedGiftCardApi } from "../../../../services/auth";
import { toast } from "react-toastify";
import { cloneDeep } from 'lodash';


interface IGiftCardProps {
  fromPage?: string;
}

interface IGiftCardRef {
  submit: () => any;
}

const GiftCard = forwardRef(
  (props: IGiftCardProps, ref: React.Ref<IGiftCardRef>) => {
    const {
      countryId,
      brandId,
      ticketDetails,
      orderCartData,
      userDetails,
      isLoggedIn,
      savedGiftCards,
    } = useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      brandId: state.applicationReducer.brandId,
      ticketDetails: state.ticketBookingReducer.ticketDetails,
      orderCartData: state.foodBeverageReducer.orderCartData,
      userDetails: state.authReducer.userDetails,
      isLoggedIn: state.authReducer.isLoggedIn,
      savedGiftCards: state.authReducer.savedGiftCards,
    }));
    const concessionTotal = useMemo(() => {
      return concessionPriceCalculation(orderCartData);
    }, [orderCartData]);

    const total = useMemo(() => {
      return (
        (ticketDetails?.total ? ticketDetails.total : 0) + concessionTotal
      ).toFixed(2);
    }, [ticketDetails, concessionTotal]);

    const bookingFees = useMemo(() => {
      return ticketDetails?.bookingFees
        ? Number(ticketDetails?.bookingFees)
        : 0;
    }, [ticketDetails]);

    const dispatch = useDispatch();
    const [isGiftCardVisible, setIsGiftCardVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [savedCards, setSavedCards] = useState<GiftCardDetail[]>([]);
    const [giftCardError, setGiftCardError] = useState("");
    const [giftCards, setGiftCards] = useState<any>([]);
    const [giftCard, setGiftCard] = useState("");
    const [totalAmount, setTotalAmount] = useState(total);
    const [enableCreditCard, setEnableCreditCard] = useState(false);
    const [cardFormVisible, setCardFormVisible] = useState(false);
    const cardRef = useRef<ICardRef>(null);

    const currentYear = new Date().getFullYear();

    useEffect(() => {
      if (savedGiftCards) {
        const card = savedGiftCards.filter((v: GiftCardDetail) =>
          v.balance !== 0 && giftCards.findIndex((g: any) => g.card === v.GiftcardNumber) === -1
            ? true
            : false
        );
        setSavedCards(cloneDeep(card));
      }
    }, [savedGiftCards, giftCards]);

    const getGiftCards = () => {
      const saveCardData = {
        memberRefId: userDetails?.result?.memberRefId!,
        countryId: countryId,
        emailId: userDetails?.email!,
      };
      dispatch(getSavedGiftCardsWatcher(saveCardData));
    };

    useEffect(() => {
      if (userDetails && isLoggedIn) {
        getGiftCards();
      }
    }, [userDetails && isLoggedIn]);

    const giftCardAmount = useMemo(() => {
      return giftCards.reduce(
        (acc: number, v: any) => acc + Number(v.balance),
        0
      );
    }, [giftCards, totalAmount]);

    useEffect(() => {
      if (isLoggedIn && savedGiftCards.length > 0) {
        setIsGiftCardVisible(true);
      } else {
        setIsGiftCardVisible(false);
      }
    }, [savedGiftCards]);

    useImperativeHandle(ref, () => ({
      submit: () => {
        if (giftCards.length > 0) {
          if (enableCreditCard && cardFormVisible) {
            const cardDetails: any = cardRef?.current?.submit();
            if (!cardDetails) {
              return null;
            }
            const { card, action, saveCard } = cardDetails;
            return {
              cardDetails: card,
              action,
              saveCard,
              giftCards: giftCards,
              enableCreditCard: enableCreditCard,
              balance: totalAmount,
              total: total,
            };
          } else if (enableCreditCard && !cardFormVisible) {
            setGiftCardError(
              "Pay remaining balance with gift card or credit card"
            );
            return null;
          } else {
            return {
              giftCards: giftCards,
              enableCreditCard: enableCreditCard,
              total: total,
            };
          }
        } else {
          setGiftCardError("Please add at least one gift card to proceed");
          return null;
        }
      },
    }));

    const verifyGiftcard = async (giftCard: string, index: number = -1) => {
      const response: any = await checkGiftCardBalanceApi({
        countryId: countryId,
        cardNumber: giftCard,
      });

      if (response.status) {
        if (!Number(response.balance)) {
          toast.error("Insufficient balance");
          if (index !== -1) {
            const cards = [...savedCards];
            cards[index]["balance"] = Number(response.balance);
            setSavedCards(cards);
          } else {
            setGiftCard('')
          }
          return;
        }
        const cards = giftCards;
        let cardTotalAmounts = cards.reduce(
          (acc: number, v: any) => acc + Number(v.balance),
          0
        );

        let totalCardAmount = cardTotalAmounts + Number(response.balance);
        cards.push({
          card: giftCard,
          actualBalance: response.balance,
          balance:
            totalCardAmount <= total
              ? Number(response.balance)
              : total - cardTotalAmounts,
        });
        setGiftCards([...cards]);
        if (totalCardAmount < total) {
          setTotalAmount(total - totalCardAmount);
          setEnableCreditCard(true);
        } else {
          setTotalAmount(0);
          setEnableCreditCard(false);
        }
        setGiftCard("");
      } else {
        setGiftCardError(response.error);
      }
    };

    const checkBalance = async () => {
      setGiftCardError("");
      if (!giftCard) {
        setGiftCardError("Gift card number is required");
        return;
      }
      const hasDuplicate = giftCards.find((v: any) => v.card === giftCard);

      if (hasDuplicate) {
        setGiftCardError("Gift Card Number Duplicate not allowed");
        return;
      }

      verifyGiftcard(giftCard);
    };

    const removeCard = async (index: number) => {
      setGiftCardError("");
      const cards = giftCards;
      cards.splice(index, 1);
      const balance = cards.reduce(
        (acc: number, v: any) => acc + Number(v.balance),
        0
      );
      if (balance < total) {
        setTotalAmount(total - balance);
        setEnableCreditCard(true);
      } else {
        setTotalAmount(0);
        setEnableCreditCard(false);
      }
      setGiftCards([...cards]);
    };

    const onInputChange = (e: any) => {
      const value = numberOnly(e);
      if (value !== undefined) {
        setGiftCard(value);
      }
    };

    const addSaveCard = (card: GiftCardDetail, index: number) => {
      verifyGiftcard(card.GiftcardNumber!, index);
    };

    const checkCurrentBalance = async (card: GiftCardDetail, index: number) => {
      const response: any = await checkGiftCardBalanceApi({
        countryId: countryId,
        cardNumber: card.GiftcardNumber,
      });

      if (response.status) {
        const balance = Number(response.balance);
        const cards = [...savedCards];
        cards[index]["balance"] = balance;
        setSavedCards(cards);
      }
    };

    const deleteCard = async (card: GiftCardDetail) => {
      await removedGiftCardApi({
        memberRefId: userDetails?.result?.memberRefId!,
        cardNumber: card?.GiftcardNumber!,
        countryId: `${countryId}`,
        emailId: userDetails?.email!,
      }).then((res: any) => {
        if (
          res?.data?.data &&
          res?.data?.data.status &&
          res?.data?.data.status === "true"
        ) {
          toast.success("Gift Card removed successfully");
          getGiftCards();
        }
      });
    };
    return (
      <div className="gitcard-wrapper">
        <div className="row">
          <div className={`col-12  mt-4 ${props.fromPage === 'membership' ? 'col-md-8' : 'col-md-12'}`}>
            <div>
              {giftCards.map((v: any, i: number) => (
                <div key={`gift_${i}`}>
                  <label className="form-label">Gift Card Number</label>
                  <div className="form-group input-group">
                    <TextInput
                      readonly={true}
                      value={v.card}
                      style={{ backgroundColor: "#e9ecef" }}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        onClick={() => removeCard(i)}
                        className="btn btn_minus"
                      ></button>
                    </div>
                  </div>
                  <div className="info_message mb-3">
                    <span>Card Balance: ${v.actualBalance}</span>
                  </div>
                </div>
              ))}
            </div>
            {isGiftCardVisible && savedCards.length > 0 ? (
              <div className="saved_card_list_wrap">
                <div className="saved_card_wrap">
                  <div className="title">Your saved gift cards</div>
                  <div className="body">
                    <div className="card_details_wrap mCustomScrollbar">
                      {savedCards.map((card: GiftCardDetail, index: number) => (
                        <div className="card_details_list">
                          <div className="state p-primary">
                            <div>
                              <label className="form-label">
                                <span>
                                  {card.GiftcardNumber
                                    ? card.GiftcardNumber
                                    : ""}
                                </span>
                              </label>
                            </div>
                            <div className="ml-2">
                              {"balance" in card ? (
                                <span>Card Balance: ${card.balance}</span>
                              ) : null}
                            </div>
                          </div>

                          <div>
                            {card.balance === 0 ? (
                              <button
                                disabled={!totalAmount}
                                className="btn btn-delete"
                                onClick={() => deleteCard(card)}
                              >
                                Delete
                              </button>
                            ) : (
                              <button
                                disabled={!totalAmount}
                                className="btn btn-black"
                                onClick={() => addSaveCard(card, index)}
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {errorMessage ? (
                    <div className="error_message mb-3 text-center">
                      <span>{errorMessage}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            {totalAmount > 0 ? (
              <>
                <div>
                  <label className="form-label">Gift Card Number</label>
                  <TextInput
                    type="text"
                    value={giftCard}
                    maxLength={19}
                    placeholder={"Enter card number"}
                    onBlur={checkBalance}
                    onChange={onInputChange}
                  />
                </div>
                <div className="error_message">
                  <span> {giftCardError}</span>
                </div>
              </>
            ) : null}
          </div>
        </div>
        {totalAmount > 0 ? (
          <div className="info_message">
            <span>
              {total - bookingFees < giftCardAmount
                ? `Balance Payment Amount: $${totalAmount.toFixed(2)}`
                : `Balance Payment Amount: $${(
                  totalAmount - bookingFees
                ).toFixed(2)} + Booking Fee: $${bookingFees.toFixed(2)}`}
            </span>
          </div>
        ) : null}
        {enableCreditCard ? (
          <>
            <div className="col-12">
              <div className="custom_checkbox save_card_list_checkbox">
                <input
                  type="checkbox"
                  checked={cardFormVisible}
                  onClick={(event) => setCardFormVisible(!cardFormVisible)}
                />
                <div className="state p-primary">
                  <label className="form-label">
                    Pay remaining balance with credit card
                  </label>
                </div>
              </div>
            </div>
            {cardFormVisible ? (
              <div>
                <CreditCard ref={cardRef} fromPage={props.fromPage} />
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    );
  }
);

export default GiftCard;
